import React, { useEffect, useRef } from "react";
import { BrowserRouter } from "react-router-dom";
import routes, { renderRoutes } from "./routes";
import { AuthProvider } from "./contexts/JWTAuthContext";
import { ModalsProvider, Notifier } from "./components";
import { SnackbarProvider } from "notistack";
import { SkeletonTheme } from "react-loading-skeleton";
import socket from "./socket/socket";
import { I18nextProvider } from "react-i18next";
import i18n from "./services/i18n";
import { CssBaseline } from "@mui/material";

const App = () => {
  const hiddenTimestampRef = useRef(0);
  const counterIntervalRef = useRef(null);
  const counterDuration = parseInt(process.env.REACT_APP_COUNTER_DURATION);

  useEffect(() => {
    // Function to reload the app
    const reloadApp = () => {
      window.location.reload();
    };

    // Function to start the counter
    const startCounter = () => {
      hiddenTimestampRef.current = Date.now();
      // Count every second
      counterIntervalRef.current = setInterval(() => {
        if (!document.hidden) {
          clearCounter();
          socket.connected === false && socket.connect();
        }
      }, 1000);
    };

    // Function to clear the counter
    const clearCounter = () => {
      if (counterIntervalRef.current) {
        clearInterval(counterIntervalRef.current);
        counterIntervalRef.current = null;
      }
    };

    // Function to disconnect the socket after 5 seconds of tab being hidden
    const disconnectSocket = () => {
      setTimeout(() => {
        if (document.hidden) {
          socket.disconnect();
        }
      }, counterDuration * 1000);
    };

    let lastHiddenTimestamp = 0;

    // Check if the tab visibility changes
    const handleVisibilityChange = () => {
      if (document.hidden) {
        // Tab is hidden, store the timestamp and start the counter
        lastHiddenTimestamp = Date.now();
        startCounter();
        disconnectSocket();
      } else {
        // Tab is visible again, check if 5 minutes have passed and then reload
        const elapsedTime = Math.floor(
          (Date.now() - lastHiddenTimestamp) / 1000
        );
        if (
          elapsedTime >= counterDuration &&
          !window.location.pathname.includes("bot") &&
          window.location.pathname !== "/help-desk/articles/create"
        ) {
          reloadApp();
        }
        // Clear the counter
        clearCounter();
        socket.connected === false && socket.connect();
      }
    };

    // Attach the event listener to handle visibility changes
    document.addEventListener("visibilitychange", handleVisibilityChange);
    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      // Clear the counter if the component is unmounted
      clearCounter();
    };
  }, [counterDuration]);

  return (
    <div dir="auto">
      <CssBaseline />
      <SnackbarProvider
        autoHideDuration={3000}
        maxSnack={12}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Notifier />
        <BrowserRouter>
          <I18nextProvider i18n={i18n}>
            <AuthProvider>
              <SkeletonTheme color="#ebebeb" highlightColor="#000">
                {renderRoutes(routes)}
              </SkeletonTheme>
              <ModalsProvider />
            </AuthProvider>
          </I18nextProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </div>
  );
};

export default App;
